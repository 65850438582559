import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import axios from 'axios'
import jwtEncode from 'jwt-encode'
import { Helmet } from "react-helmet";

function Term() {
    const [data, setData] = useState({})
    const [image, setImage] = useState("loading")
    const titleTermId = (window.location.href).split("/").pop()
    const now = new Date()
    const nowTime = now.setSeconds(now.getSeconds());
    const expTime = now.setSeconds(now.getSeconds() + 7200);
    const serviceKey = "3a1cfc61-2a08-4485-9af7-ef350ec93673"
    const accessToken = jwtEncode({ 'exp': `${expTime}`, 'iat': `${nowTime}`, 'sub': 'nepali_terms_frontend' }, 'IHbfWpbj5vnWlsMLHBApdg', 'HS256')

    document.title = "Nepali Terms | " + titleTermId
    const meta_title = document.querySelector('meta[name="title"]')
    meta_title.setAttribute("content", titleTermId)

    
    useEffect(() => {
        axios.get(`https://nepali-terms-backend-dot-nepali-terms.uc.r.appspot.com/term/service/${serviceKey}/term_id/${titleTermId}`, {
            headers: { Authorization: `Bearer ${accessToken}`}
        }).then((response) => {
            setData(response.data.term)
        })
        },[]
    )

    useEffect(() => {
        axios.get(`https://nepali-terms-backend-dot-nepali-terms.uc.r.appspot.com/term/service/${serviceKey}/${titleTermId}/get_image`, {
            headers: { Authorization: `Bearer ${accessToken}`}
        }).then((response) => {
            setImage(response.data.image)
        })
        },[]
    )
    
    return (
        <>
            <Helmet>
               <meta name="description" content={"Explore the meaning of nepali term "+ data.terms_english +" in Nepali"} />
            </Helmet> 
            <Navbar headerText= { data.terms_english }/>
            <div className="container">
                <div class="row">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-6">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td><strong>Term in Roman Nepali</strong></td>
                                    <td id="terms_english">{ data.terms_english }</td>
                                </tr>
                                <tr>
                                    <td><strong>Term Description in English</strong></td>
                                    <td id="terms_description_english">{ data.terms_description_english}</td>
                                </tr>
                                <tr>
                                    <td><strong>Images</strong></td>
                                    {image === "loading" ? <td id="terms_image">Loading...</td> :
                                    image && <td id="terms_image"><img id="image_place_holder" height="200px" width="300px" src={`data:image/jpeg;base64,${image}`} alt="" /></td>}
                                    {!image &&  <td id="terms_image"><em>Images not available</em></td>}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-sm-2"></div>
                </div>
            </div>
        </>
    )
}

export default Term



