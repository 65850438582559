import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import jwtEncode from 'jwt-encode'
import IdleTimer from './IdleTimer';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

function SubmitTerm() {
    const [counter, setCounter] = useState("")
    const [englishTerm, setEnglishTerm] = useState("");
    const [englishDescription, setEnglishDescription] = useState("");
    const [nepaliTerm, setNepaliTerm] = useState("");
    const [nepaliDescription, setNepaliDescription] = useState("");
    const [ourImage, setOurImage] = useState("");
    const [isTimeout, setIsTimeout] = useState(false);
    const now = new Date();
    const nowTime = now.setSeconds(now.getSeconds());
    const expTime = now.setSeconds(now.getSeconds() + 7200);
    const serviceId = "b3f6935c-9913-4cf5-a29f-22bf05784c10";
    const serviceName = "nepali_terms_update_access";
    const accessToken = jwtEncode({ 'exp': `${expTime}`, 'iat': `${nowTime}`, 'sub': `${serviceName}` }, 'cjKrhcUubxDdW-jNkqMCiw', 'HS256')
    const ref = useRef();
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state) => state.authenticate.isAuthenticated)
    
    document.title = "Nepali Terms | admin"        


    useEffect(() => {
        axios.get('https://nepali-terms-backend-dot-nepali-terms.uc.r.appspot.com/term/service/' + serviceId + '/get_total_number_of_terms', {
            headers: { Authorization: `Bearer ${accessToken}` }
        }).then((response) => {
            setCounter(response.data.total)
        })
    }, []
    )

    function handleSubmit(e) {
        e.preventDefault()

        const termId = englishTerm.toLowerCase().replaceAll(' ', '_');
        let postData = JSON.stringify({
            "term_id": termId,
            "terms_english": englishTerm,
            "terms_nepali": nepaliTerm,
            "terms_description_english": englishDescription,
            "terms_description_nepali": nepaliDescription,
        })

        let config = {
            method: 'post',
            url: `https://nepali-terms-backend-dot-nepali-terms.uc.r.appspot.com/term/service/${serviceId}`,
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            data: postData
        };
        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
        if (ourImage !== "") {
            let fd = new FormData();
            fd.append("image", ourImage);
            axios.post("https://nepali-terms-backend-dot-nepali-terms.uc.r.appspot.com/term/service/" + serviceId + "/" + termId + "/upload_image", fd, {
                headers: {
                    'content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${accessToken}`
                }
            })
        }
        sweetTest()

        setEnglishTerm("")
        setEnglishDescription("")
        setNepaliTerm("")
        setNepaliDescription("")
        setOurImage(ref.current.value = "");
    }

    useEffect(() => {
        const timer = new IdleTimer({
            timeout: 50,
            onTimeout: () => {
                setIsTimeout(true);
            },
            onExpired: () => {
                setIsTimeout(true);
            }
        });
        return () => {
            timer.cleanUp();
        };
    }, []);
    
    function sweetTest() {
        Swal.fire(
            {
                icon: "success",
                text: 'Successfully Submitted'
            }
        )
    }

    return (
        isTimeout || !isAuthenticated ? navigate('/login') :
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ textAlign: "center" }}>
                <h1><Link to="/"><img src='/static/nepalitermslogo.png' width="50" height="30" alt="logo" /></Link></h1><br /><br />
                <span style={{ fontSize: "2em" }}><strong>Total Terms : {counter}</strong></span>
            </nav>
            <br />
            <br />
            <div className="container">
                <div className="row">
                    <div className="col-sm-2"></div>
                    <form onSubmit={handleSubmit}>
                        <div className="col-sm-7">
                            <div className="mb-3" style={{ marginBottom: "1rem" }}>
                                <label htmlFor="english_term" className="form-label">English Term</label>
                                <input type="text" value={englishTerm} className="form-control" id="english_term" name="english_term" onChange={(e) => setEnglishTerm(e.target.value)} />
                            </div>
                            <div className="mb-3" style={{ marginBottom: "1rem" }}>
                                <label htmlFor="english_description" className="form-label">English Description</label>
                                <textarea value={englishDescription} className="form-control" id="english_description" name="english_description" rows="4" onChange={(e) => setEnglishDescription(e.target.value)}></textarea>
                            </div>
                            <div className="mb-3" style={{ marginBottom: "1rem" }}>
                                <label htmlFor="nepali_term" className="form-label">Nepali Term</label>
                                <input value={nepaliTerm} type="text" className="form-control" id="nepali_term" name="nepali_term" onChange={(e) => setNepaliTerm(e.target.value)} />
                            </div>
                            <div className="mb-3" style={{ marginBottom: "1rem" }}>
                                <label htmlFor="nepali_description" className="form-label">Nepali Description</label>
                                <textarea value={nepaliDescription} className="form-control" id="nepali_description" name="nepali_description" rows="4" onChange={(e) => setNepaliDescription(e.target.value)}></textarea>
                            </div>
                            <div className="mb-3" style={{ marginBottom: "1rem" }}>
                                    <input id="terms_image" name="terms_image" type="file" multiple={ false} accept='.jpg,.jpeg,.png' ref={ref} onChange={(e) => setOurImage(e.target.files[0])} />
                            </div>
                            <button id="submit" type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
                <div className="row">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-7">
                            <label id="result" style={{ marginTop: "1rem" }}></label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-7">
                        <label id="error" style={{ marginTop: "1rem", color: "red" }}></label>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubmitTerm