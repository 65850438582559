import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isAuthenticated : false
}

const AuthenticateSlice = createSlice({
  name: "authenticate",
  initialState,
    reducers: {
        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload.value
      }
  }
});

export const { setIsAuthenticated } = AuthenticateSlice.actions

export default AuthenticateSlice.reducer