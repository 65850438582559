import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import axios from 'axios'
import { Link } from 'react-router-dom'
import jwtEncode from 'jwt-encode'
import { Helmet } from "react-helmet";

function Home() {
    const [search, setSearch] = useState(null)
    const [data, setData] = useState([])
    const [searchData, setSearchData] = useState([])
    const now = new Date()
    const nowTime = now.setSeconds(now.getSeconds());
    const expTime = now.setSeconds(now.getSeconds() + 7200);
    const serviceKey = "3a1cfc61-2a08-4485-9af7-ef350ec93673";
    const accessToken = jwtEncode({ 'exp': `${expTime}`, 'iat': `${nowTime}`, 'sub': 'nepali_terms_frontend' }, 'IHbfWpbj5vnWlsMLHBApdg','HS256')
    
    document.title = "Nepali Terms"        

    useEffect(() => {
        axios.get(`https://nepali-terms-backend-dot-nepali-terms.uc.r.appspot.com/term/service/${serviceKey}/count/200`, {
            headers: { Authorization: `Bearer ${accessToken}`}
        }).then((response) => {
            setData(response.data)
        })
        },[]
    )
    
    useEffect(() => {
        axios.get(`https://nepali-terms-backend-dot-nepali-terms.uc.r.appspot.com/term/service/${serviceKey}/search/${search}`, {
                headers: { Authorization: `Bearer ${accessToken}`}
            }).then((response) => {
                setSearchData(response.data)
            })
        },[search]
        )

    function getSearchValue(e) {
        let value = e.target.value.toLowerCase()
        let valueOther = value.replaceAll(' ', '_')
        if (valueOther) {
            setSearch(value)
        } else {
            setSearch(value)
        }
    }
    
    return (
        <>
            <Helmet>
               <meta name="description" content="Nepali Terms is a website where you can find the meaning of the common nepali words and phrases that are used" />
               <meta name="what" content="Traditional Terms" />
               <meta name="keywords" content="Nepali, Terms, Nepal, In Nepali, In Nepal, Meaning, Means, maney" />
            </Helmet>
            <Navbar headerText = "Terms" />
            <br /><br />
            <div className="container" style={{fontSize: "16px"}}>
                <div className="col-sm-2"></div>
                <div className="col-sm-7">
                    <input className="form-control" id="search-input" type="text" placeholder="Search.." onChange={ getSearchValue }/>
                    <br />
                    {search ?
                        <table className="table">
                            <tbody id="list_of_recent_50">
                                {searchData.map((value) =>
                                        <tr>
                                        <td><Link to={`/term/${value.term_id}`}>{value.terms_english}</Link></td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                        :
                        <table className="table">
                            <tbody id="list_of_recent_50"  style={{textAlign: "center"}}>
                                {data.map((value) =>
                                    
                                        <tr>
                                            <td><Link to={`/term/${value.term_id}`}>{value.terms_english}</Link></td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    }
                </div>
                <div className="col-sm-2"></div>
            </div>
        </>
    )
}

export default Home