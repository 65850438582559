import React from 'react'
import { Link } from 'react-router-dom'
function Navbar(props) {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ textAlign: "center" }}>
                <span><Link to="/"><img src='/static/nepalitermslogo.png' width="50" height="30" alt="logo" /></Link></span><br /><br />
                <h1><span style={{ fontSize: "2em" }}><strong>{props.headerText}</strong></span></h1>
            </nav>
        </>
    )
}

export default Navbar