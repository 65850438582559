import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setIsAuthenticated } from '../features/AuthenticateSlice'
import Swal from 'sweetalert2';

function Login() {
    const navigate = useNavigate()
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const dispatch = useDispatch()

    document.title = "Nepali Terms | login"        
    
    function handleSubmit(e) {
        e.preventDefault()        
        if (username === "admin" && password === "pass@nep@terms@31@31@221") {
            sweetTestSuccess()
            navigate('/submitterm')
            dispatch(setIsAuthenticated({
                value: true
            }
            ))
        } else {
            sweetTestFailure()
            navigate('/login')
            dispatch(setIsAuthenticated({
                value: false
            }
            ))

        }
        setPassword("")
        setUsername("")
    }
    function sweetTestSuccess() {
        Swal.fire(
            {
                icon: "success",
                text: 'Successfully Logged In'
            }
        )
    }
    function sweetTestFailure() {
        Swal.fire(
            {
                icon: "danger",
                text: 'Login Unsuccessful'
            }
        )
    }
    
    return (
        <>
            <Navbar headerText="Login"/>
            <br />
            <br />
            <div className="container">
                <div className="row">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-6">
                        <form onSubmit={handleSubmit} method="post">
                            <div className="mb-3" style={{ marginBottom: "1rem" }}>
                                <label htmlFor="uname" className="form-label">Username</label>
                                <input type="text" value={ username } className="form-control" id="uname" name="uname" onChange={ (e)=>setUsername(e.target.value) } required />
                            </div>
                            <div className="mb-3" style={{ marginBottom: "1rem" }}>
                                <label htmlFor="psw" className="form-label">Password</label>
                                <input type="password" value={ password } className="form-control" id="psw" name="psw" onChange={ (e)=>setPassword(e.target.value) }/>
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-6">
                        <label id="error" style={{ marginTop: "1rem" }}></label>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login