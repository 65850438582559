import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from '../pages/Home'
import Login from '../pages/Login'
import SubmitTerm from '../pages/SubmitTerm'
import Term from '../pages/Term'

function RouterPage() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/login" element={<Login />} />
                <Route path="/submitterm" element={<SubmitTerm />} />
                <Route path="/term/:topicId" element={<Term />} />
            </Routes>
        </Router>
    )
}

export default RouterPage